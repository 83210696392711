import Home from './Pages/Home.svelte';
import Contact from './Pages/Contact.svelte';

const DefaultPage = Home;
export const pages = [
  { path: '/', href: '/', name: 'Home', component: DefaultPage, showInMainNav: true },
  { path: '/contact', href: '/index.html?page=contact', name: 'Contact', component: Contact, showInMainNav: true },
]

const address = (line1, city, state, zip5, mapLink) => ({ line1, city, state, zip5, mapLink, toString: () => `${line1}, ${city}, ${state}, ${zip5}`});
const site = ({
    name: 'Corporate Success Mantras',
    owner: 'Corporate Success Mantras',
    slogan: 'We are great at business!',
    email: 'aaa@abc.com',
    logo: './images/logo.png',
    logoMobile: './images/logo.png',
    address: address("123 Main Street", "City", "ST", "12345", ""),
    contactPrompt: 'Submit Your Mantra',
    social: {
    }
  });  

  export default site;

  const quote = (id, text) => ({ id, text });
  export const quotes = [
    quote(1, "Perception is Reality."),
    quote(2, "Socialize with and assist people higher on the Org Chart than your boss."),
    quote(3, "If you worked on a successful project in any way...<br><br>Take full credit for the whole project."),
    quote(4, "Preventing problems doesn't get you promoted.<br><br>Only heroics and flashy accomplishments can do that."),
    quote(5, "People would rather have the wrong thing created than have to provide specific requirements."),
    quote(6, "No-one wants accurate timelines.<br><br>They just want to feel that things can happen fast."),
    quote(7, "If people don't know that you did it...<br><br>Then it never happened."),
    quote(8, "Endorsements<br><br>><br><br>Job Performance"),
    quote(9, "If you did something great more than a week ago...<br><br>Then it never happened."),
    quote(10, "Everyone is either an asset or an obstacle.<br><br>Including you."),
    quote(11, "There's no shortage of remarkable ideas.<br><br>What's missing is the will to execute them."),
    quote(12, "When you receive advice, the best course of action is typically<br><br>Do the exact opposite."), 
    quote(13, "People want to believe that when they make a request of you<br><br>It will be top priority."),
    quote(14, "Opportunities don't happen.<br><br>You create them."),
    quote(15, "All progress takes place outside the comfort zone."),
    quote(16, "Middlemen have a corrupted mentality.<br><br>They speak for no-one but themselves."),
    quote(17, "A successful man is one who can lay a firm foundation with the bricks that others throw at him."),
    quote(18, "Focus all your energy not on fighting the old<br><br>But on building the new."),
    quote(19, "It’s not about ideas.<br><br>It’s about making ideas happen."),
    quote(20, "You are not dealing with creatures of logic.<br><br>You are dealing with creatures of emotion."),
    quote(21, "Giving out praise and positivity to specific individuals increases your social status."),
    quote(22, "You have to learn the rules of the game.<br><br>Then you have to play better than anyone else."),
    quote(23, "The secret of success is honesty and fair dealing.<br><br>If you can fake that, you’ve got it made."),
    quote(24, "You are the entrepreneur of your career<br><br>Even if you are employed by others."),
    quote(25, "Motivation is the art of getting people to do what you want them to do<br><br>Because <i>they</i> want to do it."),
    quote(26, "If you do everything your boss wants done<br><br>You'll merely be rated 'Meets Expectations.'"),
    quote(27, "Never complain. Only powerless people complain."),
    quote(28, "Only the customer cares about the actual user experience.<br><br>No-one at the corporation cares."),
    quote(29, "Your boss would rather have comfort than profit or efficiency."),
    quote(30, "Ensure that nothing that goes wrong can be tied or traced to you."),
  ]
