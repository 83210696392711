<script>
  import Container from '../Elements/Container.svelte';	
  import { onMount } from 'svelte';

  import { quotes } from '../static-content';

  let quote = '';
  let quoteIndex = -1;
  onMount(() => {
    selectRandomQuote();
  });
  
  const selectRandomQuote = () => {
    let nextIndex = -1
    while (nextIndex < 0 || nextIndex == quoteIndex)    
      nextIndex = Math.floor(Math.random() * quotes.length);
    quoteIndex = nextIndex;
    quote = quotes[quoteIndex].text;    
  } 

  const onNext = _ => {
    console.log('next');
    replayAnimations();
    selectRandomQuote();
  } 

  const replayAnimations = () => {
    document.getAnimations().forEach((anim) => {
      anim.finish();
      anim.play();
    });
  };

  const onKeyDown = e => {
    console.log(e);
  }
</script>

<svelte:window on:keydown={onKeyDown}/>

<Container>
  <section class="center-vh">
    <div></div>
    <div class="centerish">
      <h1 class="center fade-in-text">
        {@html quote}
      </h1>
    </div>
    <button class="centerish" on:click={onNext}><img src="/images/right-arrow.png" alt="next"></button>
  </section>
</Container>

<style>
  section {
    padding: 1em;
    min-height: 82vh;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }

  button {
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    height: 64px;
    max-height: 64px;
    width: 64px;
    max-width: 64px;
  }

  button:hover {  
    filter: brightness(120%);
  }

  .centerish {
    top: -1em; 
  }

  h1 {
    text-align: center;
    width: 800px;
    max-width: 90vw;
    font-size: 3em;
    position: relative;
  }

  @media screen and (max-width: 600px) {
    h1 {
      font-size: 2em;
    }

    section {
      flex-direction: column;
      min-height: 72vh;
    }
  }

  .fade-in-text {
    animation: fadeIn linear 3s;
    -webkit-animation: fadeIn linear 3s;
    -moz-animation: fadeIn linear 3s;
    -o-animation: fadeIn linear 3s;
    -ms-animation: fadeIn linear 3s;
  }

  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
</style>