<script>
  export let company;
</script>

<footer>
  <div class="">
    <p>This site is solely for entertainment purposes.<br>None of these mantras constitute official career advice.<br><br>No animals were harmed in the creation of these mantras.</p>
  </div>
</footer>

<style>
  footer {
    background-color: var(--footer-background-color);
    color: var(--footer-color);

    margin: 0 auto;
    padding-left: 1em;
    padding-right: 1em;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    justify-content: flex-end;
    justify-items: flex-end;
    padding-bottom: 1em;
  }

  p {
    color: var(--footer-color);
    margin: 0.2em;
    line-height: 1.2em;
    text-align: center;
  }
</style>
